import algoliasearch from "algoliasearch/lite"
import { default as React, useState, useMemo, Component} from "react"
import {InstantSearch} from "react-instantsearch-dom"
import { ThemeProvider } from "styled-components"
import * as PropTypes from "prop-types";
import StyledSearchBox from "./styled-search-box"
import Results from "../Results";
import Filters from "./filters";

const theme = {
	foreground: "#050505",
	background: "white",
	faded: "#888",
}

class Configure extends Component {
	render() {
		return null;
	}
}

Configure.propTypes = {filters: PropTypes.string};

export default function Search({ indices }) {
	const [query, setQuery] = useState()
	const [hasFocus, setFocus] = useState(false)
	const searchClient = useMemo(
		() =>
			algoliasearch(
				"JKWQZTTE4U",
				"e60f2bef9e1bc41e82de0d3a8fb5d74d"
			),
		[]
	)

	return (
		<ThemeProvider theme={theme}>
			<InstantSearch
				searchClient={searchClient}
				indexName={indices[0].name}
				onSearchStateChange={(searchState) => {
					//console.log("searchState", searchState)
					var {query} = searchState
					setQuery(query)
				}}
			>
				<Filters />
				
				<StyledSearchBox onFocus={() => setFocus(true)} hasFocus={hasFocus} />
				<Results
					indexName={indices[0].name}
				/>
				
			</InstantSearch>
		</ThemeProvider>
	)
}
import {
  MenuSelect,
  NumericMenu,
  Panel,
  RefinementList,
} from 'react-instantsearch-dom';
import React from 'react';
import styled from 'styled-components';

const Filters = () => (
  <FilterContainer>
    <Panel header="Verticales">
      <MenuSelect
        attribute="vertical"
        translations={{
          seeAllOption: 'Voir tout',
        }}
      />
    </Panel>

    <Panel header="Prep time">
      <NumericMenu
        attribute="duration_minutes"
        items={[
          { label: '- de 15 minutes', end: 15 },
          { label: '- de 30 minutes', end: 30 },
          { label: '- de 60 minutes', end: 60 },
        ]}
        translations={{
          all: 'Toute durée',
        }}
      />
    </Panel>

    <Panel header="Nombre de personnes">
      <NumericMenu
        attribute="number_of_people"
        items={[
          { label: 'Solo', end: 1 },
          { label: 'À deux', start: 2, end: 2 },
          { label: 'En famille', start: 3, end: 7 },
          { label: 'Grande tablée', start: 8 },
        ]}
        translations={{
          all: 'Toutes les recettes',
        }}
      />
    </Panel>

    <Panel header="Ustensiles">
      <RefinementList
        attribute="kitchenware"
        searchable
        operator="and"
        translations={{
          all: 'Toutes les recettes',
        }}
      />
    </Panel>
  </FilterContainer>
);

const FilterContainer = styled.div`
  .ais-Panel-header {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: 1px black solid;
  }

  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  justify-content: space-between;
`;

export default Filters;
